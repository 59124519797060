import { memo } from 'react';
import './input.css';
import Search from '../../../assets/search.png';
import Error from '../../../assets/error.png';
import Warning from '../../../assets/warning.png';
import OK from '../../../assets/OK.png';

export default memo(function Input(props: InputProps): JSX.Element {
	return (
		<div
			className='input_component'>
			{
				props.title ?
				<label className='title'>
					{props.title}
				</label>
				:
				null
			}
			{
				props.isMultiline ?
				<textarea
					className='input'
					placeholder={props.placeholder}
					onChange={(e) => {
						props.setValue(e.target.value);
					}}
					// value={props.value}
					defaultValue={props.value}
					disabled={props.isDisabled}
					autoCapitalize='none'
					autoComplete='off'
					autoCorrect='off'
					/>
				:
				<input
					className='input'
					placeholder={props.placeholder}
					onChange={(e) => {
						props.setValue(e.target.value);
					}}
					// value={props.value}
					defaultValue={props.value}
					disabled={props.isDisabled}
					type={props.isPassword ? 'password' : 'text'}
					autoCapitalize='none'
					autoComplete='off'
					autoCorrect='off'
        />
			}
      {props.errorDescription && props.errorDescription.length && (
        <div className="description">
          <div>
            {
              props.errorDescriptionType === 'OK'
              ? (
                <img
                  src={OK}
                  alt="OK icon"
                  width={24}
                  height={24}
                />
              ) : props.errorDescriptionType === 'Info'
                ? (
                  <img
                    src={Warning}
                    alt="Warning icon"
                    width={24}
                    height={24}
                  />
                ) : props.errorDescriptionType === 'Error'
                  ? (
                    <img
                      src={Error}
                      alt="Error icon"
                      width={24}
                      height={24}
                    />
                  ) : ''
            }
          </div>

          <div className="text_description" style={{
            color:
              props.errorDescriptionType === 'OK'
                ? '#00B58A'
                : props.errorDescriptionType === 'Info'
                  ? '#E2DC30'
                  : props.errorDescriptionType === 'Error'
                    ? '#DB5353'
                    : ''
          }}>
            {props.errorDescription}
          </div>
        </div>
      )}
			{
				props.isSearchable ?
				<button
					className='searchable'
					disabled={props.isDisabled}
					onClick={(e) => {
						e.preventDefault();
						if (props.onSubmit) {
							props.onSubmit(props.value);
						}
					}}>
					<img
						src={Search}
            alt="search icon"
						width={24}
						height={24}
          />
				</button>
				:
				null
			}
		</div>
	);
});

interface InputProps {
	title?: string;
	placeholder: string;
	value: string | undefined;
	setValue: (value: string) => void;
	onSubmit?: (value?: string) => void;
	isPassword?: boolean;
	isSearchable?: boolean;
	isDisabled?: boolean;
	isMultiline?: boolean;
  errorDescription?: string;
  errorDescriptionType?: string;
};
