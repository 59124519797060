/* eslint-disable react-hooks/rules-of-hooks */
import { memo, useEffect, useState } from 'react';
import './listItemAnimation.css';

export default memo(function listItemAnimation(): JSX.Element {
	const [isAnimating, setIsAnimating] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			setIsAnimating((prev) => !prev);
		}, 400);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className='list_item_animation'>
				<div className='row'>
					<div className={`animated first ${isAnimating ? 'active' : 'inactive'}`}/>
					<div className={`animated second ${isAnimating ? 'active' : 'inactive'}`}/>
				</div>
				<div className='row'>
					<div className={`animated third ${isAnimating ? 'active' : 'inactive'}`}/>
					<div className={`animated fourth ${isAnimating ? 'active' : 'inactive'}`}/>
				</div>
				<div className='row'>
					<div className={`animated third ${isAnimating ? 'active' : 'inactive'}`}/>
					<div className={`animated fourth ${isAnimating ? 'active' : 'inactive'}`}/>
				</div>
				<div className='row'>
					<div className={`animated fifth ${isAnimating ? 'active' : 'inactive'}`}/>
					<div className={`animated sixth ${isAnimating ? 'active' : 'inactive'}`}/>
				</div>
			<div className={`animated seventh ${isAnimating ? 'active' : 'inactive'}`}/>
		</div>
	);
});