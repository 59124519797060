import { memo, useCallback, useEffect, useState } from 'react';
import './login.css';
import Input from '../../components/reusable/input/input';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/reduxHooks';
import { logInUser, logOutUser } from '../../redux/slices/homeSlice';
import { Login as apiLogin } from '../../api/api';
import * as LocalStorageModels from '../../data/localStorageModels';
import { useIsAuthenticated, useSignIn, useSignOut } from 'react-auth-kit';
import Button from '../../components/reusable/button/button';
import { useTranslation } from 'react-i18next';

export default memo(function Login(): JSX.Element {
	const [userName, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const signIn = useSignIn();
	const logOut = useSignOut();
	const isAuthenticated = useIsAuthenticated();
	const {t} = useTranslation();

	const onLoginHandler = useCallback(async () => {
		setIsLoading(true);
		try {
			const user = await apiLogin({ userName, password });
			if (!user) {
				setUsername('');
				setPassword('');
				alert(t('login_incorrect_login'));
				setIsLoading(false);
				return;
			};
	
			if (signIn({
				expiresIn: 1440, // minutes, one day, 60*24
				token: user.token,
				tokenType: '1C',
				authState: {value:JSON.stringify(user)}
			})) {
				dispatch(logInUser({ user: user, userName: userName, password: password }));
		
				navigate('/dashboard');
				setIsLoading(false);
				return;
			};
			throw Error;
		}
		catch {
			alert(t('api_error'));
			setIsLoading(false);
		};

	}, [dispatch, navigate, password, signIn, t, userName]);

	useEffect(() => {
		if (location.state?.isError) {
			window.history.replaceState({}, document.title);
			dispatch(logOutUser);
			logOut();
			alert(t('login_auto_logout'));
		}
		else if (isAuthenticated()) {
			navigate('/dashboard');
		}
		const loginInfo = localStorage.getItem(LocalStorageModels.userItemLoginPath);
		if (loginInfo) {
			const {name, password}: LocalStorageModels.UserItemObject = JSON.parse(loginInfo);
			setUsername(name);
			setPassword(password);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='login_page'>
			<form className='login'>
				<label className='title'>
					{t('login_text_welcome')}
				</label>
				
				<Input
					placeholder={t('login_text_name_placeholder')}
					title={t('login_text_name')}
					setValue={setUsername}
					value={userName}/>

				<Input
					placeholder={t('login_text_password_placeholder')}
					title={t('login_text_password')}
					setValue={setPassword}
					value={password}
					isPassword/>
					
				<Button
					title={t(isLoading ? 'login_text_loading' : 'login_text_login')}
					onPress={(userName && password) ? onLoginHandler : null}
					disabled={isLoading}/>
			</form>
		</div>
	);
});
