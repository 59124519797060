import { memo, useCallback } from 'react';
import { Reservation } from '../../data/models/reservation';
import './listItem.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFullTime } from '../../helpers/date';

export default memo(function ListItem(props: ListItemProps) {
	const navigate = useNavigate();
	const {t} = useTranslation();
	const onPressHandler = useCallback(() => {
		navigate('/details', {
			state: {
				item: props.item,
				isHistory: props.isHistory
			}
		});
	}, [navigate, props]);

    return (
		<div className='list_item'>
			<div className='info'>
				<div className='row'>
					<label className='description'>
						{t('details_text_checkin')}
					</label>

					<label className='item_title'>
						{getFullTime(new Date(props.item.checkInDate))}
					</label>
				</div>

				<div className='row'>
					<label className='description'>
						{t('details_text_checkout')}
					</label>

					<label className='item_title'>
						{getFullTime(new Date(props.item.checkOutDate))}
					</label>
				</div>

				<div className='row'>
					<label className='description'>
						{t('details_text_roomnumber')}
					</label>

					<label className='item_title'>
						{props.item.roomNumber}
					</label>
				</div>

				<div className='row'>
					<label className='description'>
						{t('details_text_name')}
					</label>

					<label className='item_title'>
						{
							props.item.user.surname ?
							props.item.user.name + ' ' + props.item.user.surname
							:
							props.item.user.name
						}
					</label>
				</div>

			</div>
			<button
				className='button'
				onClick={(e) => {
					e.preventDefault();
					return onPressHandler();
				}}>
						{t('list_item_details')}
			</button>
		</div>
    );
});

interface ListItemProps {
	item: Reservation;
	isHistory?: boolean;
}
