import { useCallback, useMemo } from 'react';
import { SaveState,  } from '../../helpers/saveReducer';
import { ValidationType } from '../../data/models/validation';
import { Users } from '../../data/models/users';

interface Options {
  saveState: SaveState,
}

export const useValidate = (options: Options) => {
  const { saveState } = options;

  const isValidTin = useCallback((value: string) => {
    const tinRegex = /^\d+$/;

    return tinRegex.test(value)
      ? ValidationType.None
      : ValidationType.isErrorTin;
  }, [saveState]);


  const isValidEmail = useMemo(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(saveState.userEmail!)
      ? ValidationType.None
      : ValidationType.isCorrectEmail;
  }, [saveState.userEmail]);

  const isValidPhoneNumber = useMemo(() => {
    const phoneNumberRegex = /^\d{6,15}$/;

    return phoneNumberRegex.test(saveState.userPhone!)
      ? ValidationType.None
      : ValidationType.isCorrectPhoneNumber;
  }, [saveState.userPhone]);

  const isValidName =  useCallback((value: string) => {
    const nameRegex = /^[A-Za-z\s]+$/;

    return nameRegex.test(value ?? '')
      ? ValidationType.None
      : ValidationType.isCorrectName;
  }, [saveState]);

  const isValidSurname =  useCallback((value: string) => {
    const surnameRegex = /^[A-Za-z\s]+$/;

    return surnameRegex.test(value ?? '')
      ? ValidationType.None
      : ValidationType.isCorrectSurname;
  }, [saveState]);

  const lastValidate = useCallback((field: string, el: Users) => {
    if (field === 'tin') {
      return isValidTin(el.tin);
    } else if (field === 'name') {
      return isValidName(el.name);
    } else {
      return isValidSurname(el.surname);
    }
  }, [saveState.users]);

  const getDescriptionType = useCallback((index: number, el: Users, field: string) => {
    let errorDescriptionType = '';
    let currentField;

    if (saveState.users) {
      if (field === 'tin') {
        currentField = saveState.users[index].tin.length;
      } else if (field === 'name') {
        currentField = saveState.users[index].name.length;
      } else {
        currentField = saveState.users[index].surname.length;
      }
    }

    if (saveState.users && saveState.users[index]) {
      if (!currentField) {
        errorDescriptionType = 'Info';
      } else if (lastValidate(field, el)) {
        errorDescriptionType = 'Error';
      } else {
        errorDescriptionType = 'OK';
      }
    }

    return errorDescriptionType;
  }, [saveState.users]);

  return useMemo(() => ({
    isValidTin,
    isValidName,
    isValidEmail,
    isValidSurname,
    getDescriptionType,
    isValidPhoneNumber
  }), [
    isValidTin,
    isValidName,
    isValidEmail,
    isValidSurname,
    isValidPhoneNumber,
    getDescriptionType
  ]);
};
