import { memo, useCallback, useEffect } from 'react';
import './bookingDetails.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Reservation } from '../../data/models/reservation';
import { getFullTime } from '../../helpers/date';
import Button from '../../components/reusable/button/button';
import { useTranslation } from 'react-i18next';
import { Delete } from '../../api/api';
import { useAppSelector } from '../../redux/reduxHooks';
import { selectUser } from '../../redux/slices/homeSlice';

interface ApiResponse {
  success: boolean;
  statusCode: number;
}

export default memo(function BookingDetails(): JSX.Element {
	const location = useLocation();
	const item: Reservation = location.state.item;
	const isFromHistory: boolean = useLocation().state?.isHistory;
	const navigate = useNavigate();
	const {t} = useTranslation();
  const authToken = useAppSelector(selectUser)?.token!;

	const onEditHandler = useCallback(() => {
		navigate('/edit', {
			state: {
				item: item,
			}
		});
	}, [item, navigate]);

  const onDeleteHandler = useCallback(async () => {
    try {
      const response = await Delete({ id: item.id, authToken, tin: item.user.tin });

      if (!response.ok) {
        if (response.status === 401) {
          navigate('/login', {
            replace: true,
            state: {
              isError: true
            }
          });
        } else {
          alert(t('api_error_status') + response.status);
        }
        return;
      }

      const responseJson = await response.json() as ApiResponse;

      if (responseJson.statusCode === 401) {
        alert(t('api_error_status') + responseJson.statusCode);
        return;
      }

      alert(t('remove_booking_success'));

      navigate('/edit', {
        state: {
          item: item
        }
      });
    } catch (error) {
      alert(t('api_error'));
    }
  }, [item, navigate, authToken, t]);

  // useEffect(() => {
  //   if (!authToken) {
  //     navigate('/login',
  //       {
  //         replace: true,
  //         state: {
  //           isError: true
  //         }
  //       });
  //   }
  // }, [authToken]);

  // useEffect(() => {
  //   console.log(item);
  // }, [item]);

  return (
		<div
			className='page'
			style={{justifyContent: 'space-between', display: 'flex', flex: 1, flexDirection: 'column'}}>
			<div className='dashboard_page'>

				<label className='title'>
					{t('details_title')}
				</label>

				<div className='details cell'>
					<div className='row'>
						<label className='description'>
							{t('details_text_name')}
						</label>

						<label className='item_title'>
						{
							item.user?.surname ?
							item.user?.name + ' ' + item.user?.surname
							:
							item.user?.name
						}
							{}
						</label>
					</div>

					<div className='row'>
						<label className='description'>
							{t('details_text_email')}
						</label>

						<label className='item_title'>
							{item.user?.eMail}
						</label>
					</div>

					<div className='row'>
						<label className='description'>
							{t('details_text_phone')}
						</label>

						<label className='item_title'>
							{item.user?.phone}
						</label>
					</div>
				</div>

				<div className='details cell'>
					<div className='row'>
						<label className='description'>
							{t('details_text_checkin')}
						</label>

						<label className='item_title'>
							{getFullTime(new Date(item.checkInDate))}
						</label>
					</div>

					<div className='row'>
						<label className='description'>
							{t('details_text_checkout')}
						</label>

						<label className='item_title'>
							{getFullTime(new Date(item.checkOutDate))}
						</label>
					</div>
				</div>

				<div className='details cell'>
					<div className='row'>
						<label className='description'>
							{t('details_text_roomtype')}
						</label>

						<label className='item_title'>
							{item.roomType}
						</label>
					</div>

					<div className='row'>
						<label className='description'>
							{t('details_text_roomnumber')}
						</label>

						<label className='item_title'>
							{item.roomNumber}
						</label>
					</div>
				</div>

        {item.users.length > 0 && (
          <div className='details cell'>
            <label className='description'>
              {t('details_text_guests') + ':'}
            </label>

            {item.users.map((guest, i) => (
              <div className="row" key={guest.tin}>
                <label className='description'>
                  {`Guest ${i + 1}`}
                  <br/>
                </label>

                <label className='item_title'>
                  {`${guest.name} ${guest.surname}`}
                  <br/>
                </label>
              </div>
            ))}
          </div>
        )}

				<div>
					<label className='description'>
							{t('details_text_remarks')}
					</label>
					<p>
						<label className='item_title remarks'>
							{item.remarks}
						</label>
					</p>
				</div>

			</div>

			{
				!isFromHistory && (
          <>
            <div
              style={{padding: 16}}>
              <Button
                onPress={onEditHandler}
                title={t('details_text_edit')}/>
            </div>

            <div
              style={{padding: 16}}>
              <Button
                onPress={onDeleteHandler}
                title={t('details_text_delete')}/>
            </div>
          </>
        )
			}
		</div>
	);
});
