import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { Save } from '../../api/api';
import { RequstSave } from '../../data/requests/requests';
import { SaveAction, SaveState, SaveType } from '../../helpers/saveReducer';
import { useAppSelector } from '../../redux/reduxHooks';
import { selectUser } from '../../redux/slices/homeSlice';
import { useNavigate } from 'react-router-dom';
import { useValidate } from '../../pages/create/useValidate';
import { useTranslation } from 'react-i18next';

interface Options {
  saveState: SaveState,
  dispatchSaveState: Dispatch<SaveAction>,
  setIsLoading: (value: boolean) => void
}

export const useControlForm = (options: Options) => {
  const [isTinEmpty, setIsTinEmpty] = useState(true);

  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    saveState,
    setIsLoading,
    dispatchSaveState
  } = options;

  const {
    isValidName,
    isValidEmail,
    isValidSurname,
    isValidPhoneNumber,
    isValidTin
  } = useValidate({ saveState });

  const onSaveHandler = useCallback(async () => {
    if (!user) {
      navigate('/login', { replace: true });
      return;
    };

    if (isValidEmail.length
      || isValidPhoneNumber.length
      || isValidTin(saveState.userTin!).length
      || isValidName(saveState.userName!).length
      || isValidSurname(saveState.userSurname!).length
    ) {
      return;
    }

    const response = await Save(new RequstSave(
      user.token,
      saveState,
    ));

    if (!response) {
      alert(t('api_error'));
      return;
    } else if (!response.ok) {
      setIsLoading(false);
      if (response.status === 401) {
        navigate('/login',
          {
            replace: true,
            state: {
              isError: true
            }
          });
      } else {
        alert(t('api_error_status') + response.status);
      }

      return;
    }

    navigate('/dashboard', { replace: true });

    return;
  }, [navigate, saveState, t, user]);

  const handleAddGuest = useCallback(() => {
    dispatchSaveState({
      type: SaveType.SetUsers, users: [...saveState.users!, { tin: '', name: '', surname: '' }]
    });
  }, [saveState.users]);

  const handleRemoveGuest = useCallback(() => {
    dispatchSaveState({ type: SaveType.SetUsers, users: saveState.users?.slice(0, -1) });
  }, [saveState.users]);

  const handleUpdateGuest = useCallback((value: string, index: number, part: string) => {
    const updatedUsers = saveState.users!.map((el, i) => {
      if (i === index) {
        if (part === 'name') {
          return { ...el, name: value };
        } else if (part === 'surname') {
          return { ...el, surname: value };
        } else if (part === 'tin') {
          return {...el, tin: value};
        }
      }
      return el;
    });

    dispatchSaveState({ type: SaveType.SetUsers, users: updatedUsers });
  }, [saveState]);

  useEffect(() => {
    if (!saveState.userTin || saveState.userTin?.trim() === '') {
      setIsTinEmpty(true);
    } else {
      setIsTinEmpty(false);
    }
  }, [saveState.userTin]);

  return useMemo(() => ({
    isTinEmpty,
    onSaveHandler,
    handleAddGuest,
    handleRemoveGuest,
    handleUpdateGuest
  }), [
    isTinEmpty,
    onSaveHandler,
    handleAddGuest,
    handleRemoveGuest,
    handleUpdateGuest]);
};
