import { CurrentUser } from '../data/models/currentUser';
import { RequestBase, RequestDelete, RequestLogin, RequestTiNCheck, RequstSave } from '../data/requests/requests';

const API_BASE_URL = process.env.API_BASE_URL;

const routes = {
  login: 'https://reservation.orbi.ge/api/login',
  reservations: 'https://reservation.orbi.ge/api/reservation',
  rooms: 'https://reservation.orbi.ge/api/rooms',
  checkTin: 'https://booking-api.soft-unity.com/api/customers',
  save: 'https://reservation.orbi.ge/api/save',
  price: 'https://reservation.orbi.ge/api/price',
  delete: 'https://reservation.orbi.ge/api/delete'
};

export async function Login(request: RequestLogin): Promise<CurrentUser | null> {
	const response = await fetch(routes.login,
		{
		method: 'POST',
		body: JSON.stringify(request),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	});

	if (!response.ok) {
		return null;
	};

	return await response.json();
};

export async function GetReservations(request: RequestBase): Promise<Response> {
	const response = await fetch(routes.reservations, {
		method: 'POST',
		body: JSON.stringify(request),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	});

	return response;
};

export async function GetRooms(request: RequestBase): Promise<Response> {
	const response = await fetch(routes.rooms, {
		method: 'POST',
		body: JSON.stringify(request),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	});

	return response;
};

export async function CheckTin(request: RequestTiNCheck): Promise<Response> {
	const response = await fetch(routes.checkTin, {
		method: 'POST',
		body: JSON.stringify(request),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	});

	return response;
};

export async function Save(request: RequstSave) {
	const response = await fetch(routes.save, {
		method: 'POST',
		body: JSON.stringify(request),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	});

	return response;
};

export async function Delete(request: RequestDelete) {
  const response = await fetch(routes.delete, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

  return response;
};

export async function Price(request: RequstSave) {
  const response = await fetch(routes.price, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

  return response;
};
