import { memo, useCallback, useEffect, useState } from 'react';
import './dashboard.css';
import DashboardItem from '../../components/dashboard/dashboardItem';
import { useNavigate } from 'react-router-dom';
import { GetReservations } from '../../api/api';
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks';
import { selectReservationsLists, selectUser, setReservationsLists } from '../../redux/slices/homeSlice';
import { ResponseReservations } from '../../data/responses/responses';
import PullToRefresh from 'react-simple-pull-to-refresh';
import DashboardItemAnimation from '../../components/dashboard/dashboardItemAnimation';
import { useTranslation } from 'react-i18next';

export default memo(function Dashboard(): JSX.Element {
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const user = useAppSelector(selectUser);
	const reservations = useAppSelector(selectReservationsLists);
	const dispatch = useAppDispatch();
	const {t} = useTranslation();

	const onPressHandler = useCallback((route: string) => {
		navigate(route);
	}, [navigate]);

	const fetchData = useCallback(async () => {
		setIsLoading(true);
		if (!user) {
			setIsLoading(false);
			navigate('/login', { replace: true });
			return;
		};
		const response = await GetReservations({ authToken: user.token });
		if (!response) {
			setIsLoading(false);
			alert(t('api_error'));
			return;
		}
		else if (!response.ok) {
			if (response.status === 401) {
				navigate('/login', { replace: true, state: { isError: true } });
			}
			else {
				alert(t('api_error_status') + response.status);
			}
			setIsLoading(false);
			return;
		}
		const lists: ResponseReservations = await response.json();
		dispatch(setReservationsLists(lists));
		setIsLoading(false);
	}, [dispatch, navigate, t, user]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

  // useEffect(() => {
  //   console.log(reservations);
  // }, [reservations]);

	return (
		<div className='dashboard_page'>

			<PullToRefresh
				onRefresh={fetchData}
				pullingContent={(
					<div className='content'>
						{t('pull_to_refresh')}
					</div>
				)}>
			{
				isLoading ?
				<div className='content'>
					<DashboardItemAnimation/>
					<DashboardItemAnimation/>
				</div>
				:
				<div className='content'>

					<DashboardItem
						onPress={onPressHandler}
						isCurrent
						all={reservations?.current.all}
						new={reservations?.current.today}/>

					<DashboardItem
						onPress={onPressHandler}
						all={reservations?.history.all}
						new={reservations?.history.today}/>

				</div>
			}
			</PullToRefresh>

		</div>
	);
});
