import en from './en.json';
import ka from './ka.json';

export const languages = {
    en: {
        translation: en
    },
    ka: {
        translation: ka
    }
};