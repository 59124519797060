import { memo } from 'react';
import './dashboardItem.css';
import { useTranslation } from 'react-i18next';

export default memo(function dashboardItem(props: IDashboardItemProps) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const {t} = useTranslation();
	return (
		<div
			className='dashboard_item'>
			<div className='info'>

				<div className='row'>
					<label className='title'>
						{
							t(
								props.isCurrent ?
								'dashboard_item_text_current' :
								'dashboard_item_text_history'
							)
						}
					</label>
				</div>

				<div className='row'>
					<label className='description'>
						{
							t(
								props.isCurrent ?
								'dashboard_item_text_new' :
								'dashboard_item_text_finished'
							)
						}
					</label>
					<label className='description'>
						{props.new ?? 0}
					</label>
				</div>

				<div className='row'>
					<label className='description'>
						{
							t(
								props.isCurrent ?
								'dashboard_item_text_all_current' :
								'dashboard_item_text_all_history'
							)
						}
					</label>
					<label className='description'>
						{props.all ?? 0}
					</label>
				</div>

			</div>
			<button
				className='button'
				onClick={() => props.onPress(props.isCurrent ? '/current' : '/history')}>
				{
					t(
						props.isCurrent ?
						'dashboard_item_text_all_current_go' :
						'dashboard_item_text_all_history_go'
					)
				}
			</button>
		</div>
	);
});

interface IDashboardItemProps {
	isCurrent?: boolean;
	onPress: (value: string) => void;
	new?: number;
	all?: number;
}