import { memo, useCallback, useEffect, useState } from 'react';
import './header.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Dashboard_Unselected from '../../assets/dashboard_unselected.png';
import Dashboard_Selected from '../../assets/dashboard_selected.png';
import Current_Unselected from '../../assets/current_unselected.png';
import Current_Selected from '../../assets/current_selected.png';
import History_Unselected from '../../assets/history_unselected.png';
import History_Selected from '../../assets/history_selected.png';
import Add from '../../assets/add.png';
import Avatar from '../../assets/avatar.png';
import { useTranslation } from 'react-i18next';
import AccountModal from '../reusable/modal/accountModal';
import { useSignOut } from 'react-auth-kit';
import { useAppSelector } from '../../redux/reduxHooks';
import { selectUser } from '../../redux/slices/homeSlice';

type Tabs = '/dashboard' | '/current' | '/history';

export default memo(function Header(props: IHeaderProps): JSX.Element {
	const logOut = useSignOut();
	const location = useLocation();
	const navigate = useNavigate();
	const {t, i18n} = useTranslation();
	const user = useAppSelector(selectUser);
	const [selectedTab, setSelectedTab] = useState<Tabs>('/dashboard');
	const [isModal, setIsModal] = useState(false);

	const onLogoutHandler = useCallback(() => {
		logOut();
	}, [logOut]);

	useEffect(() => {
		switch (location.pathname) {
			case '/dashboard': {
				setSelectedTab('/dashboard');
				break;
			};
			case '/current': {
				setSelectedTab('/current');
				break;
			};
			case '/history': {
				setSelectedTab('/history');
				break;
			};
			default: return;
		}
	}, [location.pathname]);

	useEffect(() => {
		if (!props.isLogged) {
			setIsModal(false);
		}
	}, [props.isLogged]);

	const onLanguageHandler = useCallback((value: 'en' | 'ka') => {
		if (value === i18n.language) {
			return;
		};
		i18n.changeLanguage(value);
	}, [i18n]);

	const onNavigateHandler = useCallback((route: string) => {
		setIsModal(false);
		navigate(route);
	}, [navigate]);

	return (
		<div className='header'>
		{
			!props.isLogged ?
			<div className='item padding'>
				<label className='title'>
					{t('header_personal_cabinet')}
				</label>
			</div>
			:
			<div className='big_row'>

				<label className='title'>
					{t('header_personal_cabinet')}
				</label>

				<div className='border'/>

				<button
					className='header_row'
					onClick={() => onNavigateHandler('/dashboard')}>

					<img
						className='hide_image'
						src={
							selectedTab === '/dashboard' ?
							Dashboard_Selected :
							Dashboard_Unselected
						}
						width={18}
						height={18}/>

					<label className={`header_row_title ${selectedTab === '/dashboard' && 'selected'}`}>
						{t('header_dashboard')}
					</label>

				</button>

				<button
					className='header_row'
					onClick={() => onNavigateHandler('/current')}>

					<img
						className='hide_image'
						src={
							selectedTab === '/current' ?
							Current_Selected :
							Current_Unselected
						}
						width={18}
						height={18}/>

					<label className={`header_row_title ${selectedTab === '/current' && 'selected'}`}>
						{t('header_current')}
					</label>

				</button>

				<button
					className='header_row'
					onClick={() => onNavigateHandler('/history')}>

					<img
						className='hide_image'
						src={
							selectedTab === '/history' ?
							History_Selected :
							History_Unselected
						}
						width={18}
						height={18}/>

					<label className={`header_row_title ${selectedTab === '/history' && 'selected'}`}>
						{t('header_history')}
					</label>

				</button>

			</div>
		}
		{
			!props.isLogged ?
			<div className='language'>
				<button
					className={`language ${i18n.language === 'ka' && 'selected'}`}
					onClick={() => onLanguageHandler('ka')}>
					ქარ
				</button>

				<button
					className={`language ${i18n.language === 'en' && 'selected'}`}
					onClick={() => onLanguageHandler('en')}>
					Eng
				</button>
			</div>
			:
			<div className='big_row'>

				<button
					className='header_row'
					onClick={() => onNavigateHandler('/create')}>

					<img
						className='hide_image'
						src={Add}
						width={24}
						height={24}/>

					<label className='header_row_title'>
						{t('header_new')}
					</label>

				</button>

				<div className='border'/>

				<button
					className='header_row'
					id='account'
					onClick={() => setIsModal((prev) => !prev)}>

					<img
						src={Avatar}
						width={24}
						height={24}/>

				</button>


			</div>
		}
		{
			isModal ?
			<AccountModal
				onClose={() => setIsModal(false)}
				onLangChange={onLanguageHandler}
				onLogout={onLogoutHandler}
				userName={user?.customerName}
				userType={user?.type}/>
			:
			null
		}
		</div>
	);
});

interface IHeaderProps {
	isLogged: boolean;
};