export enum ValidationType {
  isErrorTin = 'TIN must contain only numbers',
  isCorrectName = 'Name must contain only Latin letters',
  isCorrectEmail = 'Email is invalid',
  isCorrectSurname = 'Surname must contain only letters',
  isCorrectPhoneNumber = 'Phone number must contain only numbers and min 6 symbols',
  None = '',
}

export interface Validation {
  isCorrectTin?: ValidationType,
  isCorrectName?: ValidationType,
  isCorrectEmail?: ValidationType,
  isCorrectSurname?: ValidationType,
  isCorrectPhoneNumber?: ValidationType,
}
