import { memo, useCallback, useState } from 'react';
import './button.css';

export default memo(function Button(props: IButton): JSX.Element {
	const [isLoading, setIsLoading] = useState(false);

	const onPressHandler = useCallback(async () => {
		if (props.onPress) {
			setIsLoading(true);
			await props.onPress();
			setIsLoading(false);
		};
	}, [props]);
	return (
		<button
			className='button_component'
			disabled={isLoading ? isLoading : props.disabled}
			onClick={(e) => {
				e.preventDefault();
				onPressHandler();
			}}>
				{props.title}
		</button>
	);
});

interface IButton {
	title: string;
	onPress: (() => void) | (() => Promise<void>) | (null);
	disabled?: boolean;
	awaitableText?: string;
};