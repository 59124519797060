/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SaveState } from '../../helpers/saveReducer';
import { User } from '../models/user';
import { Users } from '../models/users';

export class RequestBase {
	authToken: string;

	constructor(token: string) {
		this.authToken = token;
	};
};

export class RequestLogin {
	userName: string;
	password: string;

	constructor(userName: string, password: string) {
		this.userName = userName;
		this.password = password;
	};
};

export class RequstSave extends RequestBase {
	user: User;
	checkIn: string;
	checkOut: string;
	roomId: string;
	remarks?: string;
	id?: number;
	roomNumber?: string;
	roomType?: string;
  users?: Users[];

	constructor(
		token: string,
		state: SaveState,
	) {
		super(token);
		this.user = {
			tin: state.userTin!,
			eMail: state.userEmail,
			name: state.userName,
			phone: state.userPhone,
			surname: state.userSurname
		};
		this.checkIn = state.checkIn!.toISOString();
		this.checkOut = state.checkOut!.toISOString();
		this.roomId = state.selectedRoom!.id;
		this.remarks = state.remarks;
		this.id = state.id ?? 0;
		this.roomNumber = '';
		this.roomType = '';
    this.users = state.users ?? [];
  };
};

export class RequestTiNCheck extends RequestBase {
	tin: string;

	constructor(token: string, tin: string) {
		super(token);
		this.tin = tin;
	};
};

export class RequestDelete extends RequestBase {
  id: number;
  tin: string;

  constructor(token: string, id: number, tin: string) {
    super(token);
    this.id = id;
    this.tin = tin;
  };
}
