import { createRoot } from 'react-dom/client';
import App from './app';
import React from 'react';
import './index.css';

const domNode = document.getElementById('root');

if (!domNode) {
	throw new Error();
};

const root = createRoot(domNode);

root.render(
	<React.StrictMode>
		<App/>
	</React.StrictMode>
);