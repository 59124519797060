import { Reservation } from '../data/models/reservation';
import { Room } from '../data/models/room';
import { Users } from '../data/models/users';
import { Validation, ValidationType } from '../data/models/validation';

export enum SaveType {
	SetRooms,
	SetUser,
	SetSelectedRoom,
	SetUserTiN,
	SetUserName,
	SetUserSurname,
	SetUserPhone,
	SetUserEmail,
	SetCheckIn,
	SetCheckOut,
	SetRemarks,
  SetUsers,
  SetPrice,
};

export interface SaveState {
	id?: number;
	rooms?: Room[];
	selectedRoom?: Room;
	userTin?: string;
	userName?: string;
	userPhone?: string;
	userEmail?: string;
	userSurname?: string;
	checkIn?: Date;
	checkOut?: Date;
	remarks?: string;
  users?: Users[];
  price?: number;
};

export interface SaveAction extends SaveState {
	type: SaveType;
};

export const saveReducer = (state: SaveState, action: SaveAction): SaveState => {
	switch (action.type) {
		case SaveType.SetRooms: {
			return {
				...state,
				rooms: action.rooms
			};
		};
		case SaveType.SetSelectedRoom: {
			return {
				...state,
				selectedRoom: action.selectedRoom
			};
		};
		case SaveType.SetUser: {
			return {
				...state,
				userEmail: action.userEmail,
				userPhone: action.userPhone,
				userName: action.userName,
				userSurname: action.userSurname,
				userTin: action.userTin,
			};
		};
		case SaveType.SetUserEmail: {
			return {
				...state,
				userEmail: action.userEmail,
			};
		};
		case SaveType.SetUserPhone: {
			return {
				...state,
				userPhone: action.userPhone,
			};
		};
		case SaveType.SetUserName: {
			return {
				...state,
				userName: action.userName,
			};
		};
		case SaveType.SetUserSurname: {
			return {
				...state,
				userSurname: action.userSurname,
			};
		};
		case SaveType.SetUserTiN: {
			return {
				...state,
				userTin: action.userTin,
			};
		};
		case SaveType.SetCheckIn: {
			return {
				...state,
				checkIn: action.checkIn
			};
		};
		case SaveType.SetCheckOut: {
			return {
				...state,
				checkOut: action.checkOut
			};
		};
		case SaveType.SetRemarks: {
			return {
				...state,
				remarks: action.remarks
			};
		};
    case SaveType.SetUsers: {
      return {
        ...state,
        users: action.users
      };
    };
    case SaveType.SetPrice: {
      return {
        ...state,
        price: action.price
      };
    };
	};
};

export function checkState(state: SaveState): boolean {
	if (state.checkIn && state.checkOut && state.selectedRoom &&
		state.userEmail && state.userName && state.userSurname && state.userPhone && state.userTin) {
		return true;
	}
	else {
		return false;
	}
}

export function initSaveState(value?: Reservation): SaveState {
	if (!value) {
		const today = new Date();
		const checkIn = new Date(today);
		const checkOut = new Date(today);
		checkIn.setHours(14, 0, 0, 0);
		checkOut.setDate(today.getDate() + 1);
		checkOut.setHours(12, 0, 0, 0);
		return {
			checkIn: checkIn,
			checkOut: checkOut,
      users: [],
      price: 0,
		};
	};

	return {
		checkIn: new Date(value.checkInDate),
		checkOut: new Date(value.checkOutDate),
		remarks: value.remarks,
		userEmail: value.user.eMail,
		userName: value.user.name,
		userSurname: value.user.surname,
		userPhone: value.user.phone,
		userTin: value.user.tin,
		id: value.id,
    users: value.users ?? [],
    price: value.price ?? 0,
	};
};
