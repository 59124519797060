import { memo, useCallback, useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetRooms, Price } from '../../api/api';
import { useAppSelector } from '../../redux/reduxHooks';
import { selectUser } from '../../redux/slices/homeSlice';
import { checkState, initSaveState, saveReducer, SaveType } from '../../helpers/saveReducer';
import { Room } from '../../data/models/room';
import Cell from '../../components/create/cell';
import Button from '../../components/reusable/button/button';
import { Reservation } from '../../data/models/reservation';
import { RequstSave } from '../../data/requests/requests';
import { useTranslation } from 'react-i18next';
import './bookingCreate.css';
import Add from '../../assets/add.png';
import Minus from '../../assets/minus.png';
import { useValidate } from './useValidate';
import { ValidationType } from '../../data/models/validation';
import { useControlForm } from './useControlForm';

export default memo(function BookingCreate(): JSX.Element {
	const editableItem: Reservation = useLocation().state?.item;
	const [saveState, dispatchSaveState] = useReducer(saveReducer, initSaveState(editableItem));
	const [isLoading, setIsLoading] = useState(false);
	const [isSave, setIsSave] = useState(false);
	const navigate = useNavigate();
	const user = useAppSelector(selectUser);
	const {t} = useTranslation();
  const {
    isValidTin,
    isValidName,
    isValidEmail,
    isValidSurname,
    isValidPhoneNumber,
    getDescriptionType
  } = useValidate({ saveState });

  const {
    isTinEmpty,
    onSaveHandler,
    handleAddGuest,
    handleRemoveGuest,
    handleUpdateGuest
  } = useControlForm({
    saveState,
    setIsLoading,
    dispatchSaveState
  });

	const fetchData = useCallback(async () => {
		setIsLoading(true);

		if (!user) {
			setIsLoading(false);
			navigate('/login', { replace: true });
			return;
		};

		const response = await GetRooms({ authToken: user.token });

		if (!response) {
			setIsLoading(false);
			alert(t('api_error'));
			return;
		}
		else if (!response.ok) {
			if (response.status === 401) {
				navigate('/login', { replace: true, state: { isError: true } });
			}
			else {
				alert(t('api_error_status') + response.status);
			}
			setIsLoading(false);
			return;
		}

		const rooms: Room[] = await response.json();
		dispatchSaveState({
			type: SaveType.SetRooms,
			rooms: rooms
		});
		setIsLoading(false);

	}, [navigate, t, user]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		setIsSave(checkState(saveState));
	}, [saveState]);

  // const getTotalPrice = useCallback(() => {
  //   const checkInDate = new Date(saveState.checkIn!);
  //   const checkOutDate = new Date(saveState.checkOut!);
  //   const differenceInMilliseconds = Number(checkOutDate) - Number(checkInDate);
  //   const differenceInDays = Math.ceil(differenceInMilliseconds / 86400000);
  //   const totalPrice = Number(saveState.price) * differenceInDays;
  //
  //   dispatchSaveState({ type: SaveType.SetTotalPrice, totalPrice: totalPrice });
  // }, []);

  const onPriceHandler = useCallback(async () => {
    if (!user) {
      navigate('/login', { replace: true });
      return;
    };

    const priceResponse = await Price(new RequstSave(
      user.token,
      saveState,
    ));

    if (!priceResponse) {
      alert(t('api_error'));
      return;
    } else if (!priceResponse.ok) {
      setIsLoading(false);
      if (priceResponse.status === 401) {
        navigate('/login',
          {
            replace: true,
            state: {
              isError: true
            }
          });
      } else {
        alert(t('api_error_status') + priceResponse.status);
      }

      return;
    }

    const priceData = await priceResponse.json();
    const price = priceData.price;
    dispatchSaveState({ type: SaveType.SetPrice, price: price });

    // if (user.type === UserType.Makler) {
    //   alert(`${t('create_text_price')} 0`);
    // } else {
    //   alert(`${t('create_text_price')} ${saveState.price}`);
    // }

    // navigate('/dashboard', { replace: true });

    return;
  }, [navigate, saveState, t, user]);

  const isCorrectTIN = isValidTin(saveState.userTin!).length !== 0;

  // useEffect(() => {
  //   console.log(saveState);
  // }, [saveState]);

	return (
		<div
			className='page'
			style={{justifyContent: 'space-between', display: 'flex', flex: 1, flexDirection: 'column'}}
    >
			<div className='dashboard_page' id='create_page'>
				<div className='row'>
					<Cell
						title={t('create_text_tin')}
						placeholder={t('create_text_tin_placeholder')}
						value={saveState.userTin}
						isInput
						setValue={(value) => dispatchSaveState({
							type: SaveType.SetUserTiN,
							userTin: value
						})}
            errorDescription={ValidationType.isErrorTin}
            errorDescriptionType={!saveState.userTin?.length ? 'Info' : isValidTin(saveState.userTin) ? 'Error' : 'OK'}
          />

					<Cell
						title={t('create_text_name')}
						placeholder={
              isTinEmpty || isCorrectTIN
                ? t('create_text_tin_first')
                : t('create_text_name_placeholder')
						}
						setValue={(value) => dispatchSaveState({
							type: SaveType.SetUserName,
							userName: value
						})}
						isInput
						value={saveState.userName}
						isDisabled={editableItem ? false : !isTinEmpty ? isCorrectTIN : true}
            errorDescription={ValidationType.isCorrectName}
            errorDescriptionType={!saveState.userName?.length ? 'Info' : isValidName(saveState.userName) ? 'Error' : 'OK'}
          />

					<Cell
						title={t('create_text_surname')}
						placeholder={
							isTinEmpty || isCorrectTIN
                ? t('create_text_tin_first')
                : t('create_text_surname_placeholder')
						}
						setValue={(value) => dispatchSaveState({
							type: SaveType.SetUserSurname,
							userSurname: value
						})}
						isInput
						value={saveState.userSurname}
						isDisabled={editableItem ? false : !isTinEmpty ? isCorrectTIN : true}
            errorDescription={ValidationType.isCorrectSurname}
            errorDescriptionType={!saveState.userSurname?.length ? 'Info' : isValidSurname(saveState.userSurname) ? 'Error' : 'OK'}
          />
				</div>

				<div className='row'>

					<Cell
						title={t('create_text_phone')}
						placeholder={
							isTinEmpty || isCorrectTIN
                ? t('create_text_tin_first')
                : t('create_text_phone_placeholder')
						}
						setValue={(value) => dispatchSaveState({
							type: SaveType.SetUserPhone,
							userPhone: value
						})}
						isInput
						value={saveState.userPhone}
						isDisabled={editableItem ? false : !isTinEmpty ? isCorrectTIN : true}
            errorDescription={ValidationType.isCorrectPhoneNumber}
            errorDescriptionType={!saveState.userPhone?.length ? 'Info' : isValidPhoneNumber ? 'Error' : 'OK'}
          />

					<Cell
						title={t('create_text_email')}
						placeholder={
							isTinEmpty || isCorrectTIN
                ? t('create_text_tin_first')
                : t('create_text_email_placeholder')
						}
						setValue={(value) => dispatchSaveState({
							type: SaveType.SetUserEmail,
							userEmail: value
						})}
						isInput
						value={saveState.userEmail}
						isDisabled={editableItem ? false : !isTinEmpty ? isCorrectTIN : true}
            errorDescription={ValidationType.isCorrectEmail}
            errorDescriptionType={!saveState.userEmail?.length ? 'Info' : isValidEmail ? 'Error' : 'OK'}
          />

				</div>

				<div className='row'>

					<Cell
						title={t('create_text_checkin')}
						setDate={(value) => dispatchSaveState({
							type: SaveType.SetCheckIn,
							checkIn: value
						})}
						value={saveState.checkIn?.toString()}
						isDateTime
          />

					<Cell
						title={t('create_text_checkout')}
						setDate={(value) => dispatchSaveState({
							type: SaveType.SetCheckOut,
							checkOut: value
						})}
						value={saveState.checkOut?.toString()}
						isDateTime
          />

				</div>

				<div className='row'>
					<Cell
						title={t('create_text_checkin')}
						setSelectedItem={(value) => dispatchSaveState({
							type: SaveType.SetSelectedRoom,
							selectedRoom: value ?? undefined
						})}
						value={saveState.checkIn?.toString()}
						isPicker
						isLoading={isLoading}
						list={saveState.rooms}
						selectedItem={saveState.selectedRoom}/>

					<Cell
						title={t('create_text_checkout')}
						setSelectedItem={(value) => dispatchSaveState({
							type: SaveType.SetSelectedRoom,
							selectedRoom: value ?? undefined
						})}
						isPicker
						isPickerName
						isLoading={isLoading}
						list={saveState.rooms}
						selectedItem={saveState.selectedRoom}/>
				</div>

        <div className="new__guest__wrapper">
          <div className="new__guest__container">
            <div className="new__guest__title">
              New Guest
              <img
                className='add__icon'
                src={Add}
                width={24}
                height={24}
                onClick={handleAddGuest}
              />
            </div>

            <div className="new__guest__title">
              Remove guest
              <img
                className='icon'
                src={Minus}
                width={24}
                height={24}
                onClick={handleRemoveGuest}
              />
            </div>
          </div>
        </div>

        {!!saveState.users?.length && saveState.users?.map((el, index) => {
            return (
              <div className='row' key={index}>
                <Cell
                  title={t('create_text_tin')}
                  placeholder={t('create_text_tin_placeholder')}
                  setValue={(value) => handleUpdateGuest(value, index, 'tin')}
                  isInput
                  value={el.tin}
                  errorDescription={ValidationType.isErrorTin}
                  errorDescriptionType={getDescriptionType(index, el, 'tin')}
                />

                <Cell
                  title={t('create_text_name')}
                  placeholder={t('create_text_name_placeholder')}
                  setValue={(value) => handleUpdateGuest(value, index, 'name')}
                  isInput
                  value={el.name}
                  errorDescription={ValidationType.isCorrectName}
                  errorDescriptionType={getDescriptionType(index, el, 'name')}
                />

                <Cell
                  title={t('create_text_surname')}
                  placeholder={t('create_text_surname_placeholder')}
                  setValue={(value) => handleUpdateGuest(value, index, 'surname')}
                  isInput
                  value={el.surname}
                  errorDescription={ValidationType.isCorrectSurname}
                  errorDescriptionType={getDescriptionType(index, el, 'surname')}
                />
              </div>
            );
          })
        }

        <div className="new__guest__container-price">
          <div
            className="new__guest__price"
            style={saveState.price! > 0
              ? { backgroundColor: '#1D7B33', color: '#fff' }
              : {}
            }
          >
            {`${t('create_total_price')}: ${saveState.price}`}
          </div>
        </div>

				<Cell
					title={t('create_text_remarks')}
					placeholder={t('create_text_remarks_placeholder')}
					value={saveState.remarks}
					isInput
					isMultiline
					setValue={(value) => dispatchSaveState({
						type: SaveType.SetRemarks,
						remarks: value
					})}
        />
			</div>

			<div
				style={{padding: 16}}>
				<Button
					onPress={onSaveHandler}
					title={t('create_text_creat')}
					awaitableText={t('create_text_creating_loading')}
					disabled={!isSave}
        />
			</div>

		</div>
	);
});
