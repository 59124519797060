import { CurrentUser } from '../../data/models/currentUser';
import { ResponseReservations } from '../../data/responses/responses';
import { RootState } from '../store';
import { createSlice } from '@reduxjs/toolkit';
import { LogInPayload } from './homeSlicePayloads';
import * as LocalStorageModels from '../../data/localStorageModels';

function tryInitUser(): CurrentUser | undefined {
    const userData = localStorage.getItem(LocalStorageModels.userItemPath);

    if (!userData) {
        return undefined;
    };

    return JSON.parse(userData);
};

interface IHomeState {
    user?: CurrentUser;
    reservationLists?: ResponseReservations;
};

const initialState: IHomeState = {
    user: tryInitUser()
};

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        logInUser: (state, {payload} : {payload: LogInPayload}) => {
            state.user = payload.user;

            const loginUserData: LocalStorageModels.UserItemObject = {
                name: payload.userName,
                password: payload.password
            };
            localStorage.setItem(LocalStorageModels.userItemLoginPath, JSON.stringify(loginUserData));
            localStorage.setItem(LocalStorageModels.userItemPath, JSON.stringify(payload.user));
            state.reservationLists = undefined;
        },
        logOutUser: (state) => {
            state.user = undefined;
        },
        setReservationsLists: (state, {payload}: {payload: ResponseReservations}) => {
            state.reservationLists = payload;
        }
    },
});

export const homeReducer = homeSlice.reducer;

export const {
    logInUser,
    logOutUser,
    setReservationsLists
} = homeSlice.actions;

export const selectUser = (state: RootState) => state.homeReducer.user;
export const selectReservationsLists = (state: RootState) => state.homeReducer.reservationLists;
