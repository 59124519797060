export function getTime(date: Date): string {
	let isAM: boolean;
	let hours: number;
	const minutes = date.getMinutes();
	if (date.getHours() > 12) {
		hours = date.getHours() - 12;
		isAM = false;
	}
	else {
		hours = date.getHours();
		isAM = true;
	};
	const hoursString = hours.toString() === '0' ? '00' : hours.toString();
	const minutesString = minutes.toString() === '0' ? '00' : minutes.toString();

	return hoursString + ':' + minutesString + (isAM ? ' AM' : ' PM');
};

export function getFullTime(date: Date): string {
	return date.toLocaleDateString('en-US') + ' • ' + getTime(date);
};