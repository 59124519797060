import { memo } from 'react';
import './accountModal.css';
import { useTranslation } from 'react-i18next';
import Logout from '../../../assets/logout.png';
import { UserType } from '../../../data/models/user';

export default memo(function AccountModal(props: AccountModalProps): JSX.Element | null {
	const {t, i18n} = useTranslation();

	return (
		<div className='modal'>
			<div className='row first'>
				<label className='userName'>
					{props.userName}
				</label>
				<label className='userType'>
					{props.userType}
				</label>
			</div>
			<div className='row second'>
				<button
					className={`language ${i18n.language === 'ka' && 'selected'}`}
					onClick={() => props.onLangChange('ka')}>
					ქართული
				</button>
				<button
					className={`language ${i18n.language === 'en' && 'selected'}`}
					onClick={() => props.onLangChange('en')}>
					English
				</button>
			</div>
			<button
				className='logout'
				onClick={() => {
					props.onClose();
					props.onLogout();
				}}>

				<img
					src={Logout}
					width={24}
					height={24}/>

				<label
					className='header_row_title'
					id='logout'>
					{t('modal_logout')}
				</label>

			</button>
		</div>
	);
});

interface AccountModalProps {
	onClose: () => void;
	onLogout: () => void;
	onLangChange: (lang: 'en' | 'ka') => void;
	userName?: string;
	userType?: UserType;
};
