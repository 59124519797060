import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Header from './components/header/header';
import { AuthProvider, useIsAuthenticated } from 'react-auth-kit';
import BookingCurrent from './pages/current/bookingCurrent';
import BookingHistory from './pages/history/bookingHistory';
import Dashboard from './pages/dashboard/dashboard';
import Login from './pages/login/login';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { languages } from './i18n/languages';
import { useEffect, useState } from 'react';
import BookingDetails from './pages/details/bookingDetails';
import BookingCreate from './pages/create/bookingCreate';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

i18n.use(initReactI18next).init({
	compatibilityJSON: 'v3',
	resources: languages,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false
	}
});

export default function App(): JSX.Element {

	function Page({children, isLogin}: {children: JSX.Element, isLogin?: boolean}): JSX.Element {
		const isAuthenticated = useIsAuthenticated();
		const [auth, setAuth] = useState(false);
		useEffect(() => {
			setAuth(isAuthenticated());
		}, [isAuthenticated]);

		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					position: 'absolute',
					top: 0,
					left: 0,
					height: '100%',
					width: '100%'
				}}>
				<Header
					isLogged={auth}/>
				{auth ? children : isLogin ? children : <Navigate to={'/login'}/>}
			</div>
		);
	};

	function Router(): JSX.Element {
		return (
			<Routes>

				<Route
					path="*"
					element={<Navigate to="/dashboard" replace={true} />}/>

				<Route
					path="/login"
					element={
						<Page isLogin>
							<Login/>
						</Page>
					}/>

				<Route
					path='/dashboard'
					element={
						<Page>
							<Dashboard/>
						</Page>
					}/>

				<Route
					path="/history"
					element={
						<Page>
							<BookingHistory/>
						</Page>
					}/>

				<Route
					path="/current"
					element={
						<Page>
							<BookingCurrent/>
						</Page>
					}/>

				<Route
					path="/details"
					element={
						<Page>
							<BookingDetails/>
						</Page>
					}/>

				<Route
					path="/create"
					element={
						<Page>
							<BookingCreate/>
						</Page>
					}/>

				<Route
					path="/edit"
					element={
						<Page>
							<BookingCreate/>
						</Page>
					}/>

			</Routes>
		);
	};

	return (
		<AuthProvider
			authName='_auth'
			authType='cookie'
			cookieDomain={window.location.hostname}
			cookieSecure={window.location.protocol === 'https:'}>
			<Provider store={store}>
				<BrowserRouter>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Router/>
					</LocalizationProvider>
				</BrowserRouter>
			</Provider>
		</AuthProvider>
	);
};
