import { useTranslation } from 'react-i18next';
import './emptyList.css';
import { memo } from 'react';

export default memo(function EmptyList() {
	const {t} = useTranslation();
	return (
		<div className='empty_list'>
			<label className='title'>
				{t('empty_list_text')}
			</label>
		</div>
	);
});