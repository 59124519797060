/* eslint-disable react-hooks/rules-of-hooks */
import { memo, useEffect, useState } from 'react';
import './dashboardItemAnimation.css';

export default memo(function dashboardItemAnimation(): JSX.Element {
	const [isAnimating, setIsAnimating] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			setIsAnimating((prev) => !prev);
		}, 400);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className='dashboard_item_animation'>
			<div className={`animated circle ${isAnimating ? 'active' : 'inactive'}`}/>
			<div className={`animated big_row ${isAnimating ? 'active' : 'inactive'}`}/>
			<div className='row'>
				<div className={`animated row first ${isAnimating ? 'active' : 'inactive'}`}/>
				<div className={`animated row second ${isAnimating ? 'active' : 'inactive'}`}/>
			</div>
			<div className='row'>
				<div className={`animated row third ${isAnimating ? 'active' : 'inactive'}`}/>
				<div className={`animated row fourth ${isAnimating ? 'active' : 'inactive'}`}/>
			</div>
			<div className={`animated row fifth ${isAnimating ? 'active' : 'inactive'}`}/>
		</div>
	);
});