/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { memo } from 'react';
import './cell.css';
import Input from '../reusable/input/input';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { Room } from '../../data/models/room';
import Select from 'react-select';

export default memo(function Cell(props: CellProps) {

	return (
		<>
			<div className='editable_cell'>
				<label className='title'>
					{props.title}
				</label>

				{
					props.isInput ?
					<Input
						placeholder={props.placeholder!}
						setValue={(value) => {
							if (props.setValue) {
								props.setValue(value);
							};
						}}
						value={props.value}
						isDisabled={props.isDisabled}
						isSearchable={props.isSearchable}
						onSubmit={props.onSubmit}
						isMultiline={props.isMultiline}
            errorDescription={props.errorDescription}
            errorDescriptionType={props.errorDescriptionType}
          />
					:
					props.isDateTime ?
					<DatePicker
						defaultValue={dayjs(new Date(props.value!))}
						onChange={(value) => {
							if (props.setDate && value) {
								props.setDate(value.toDate());
							};
						}}
						minDate={dayjs(new Date(props.value!))}
						className='picker'
						format='DD/MM/YY'/>
					:
					props.isPicker ?
					<Select
						getOptionLabel={(item) => props.isPickerName ? item.name : item.roomType}
						getOptionValue={(item) => item.name}
						backspaceRemovesValue
						blurInputOnSelect
						closeMenuOnScroll
						closeMenuOnSelect
						isClearable
						isSearchable
						isLoading={props.isLoading}
						isDisabled={!props.list || props.list.length === 0}
						value={props.selectedItem}
						options={props.list}
						onChange={(value) => {
							if (props.setSelectedItem) {
								props.setSelectedItem(value);
							};
						}}
						formatGroupLabel={(e) => <div>{e.label}</div>}
						classNamePrefix='select'/>
					:
					null
				}
			</div>
		</>
	);
});

interface CellProps {
	title: string;
	value?: string;
	list?: Room[];
	selectedItem?: Room;
	setValue?: (value: string) => void;
	setDate?: (value: Date) => void;
	setSelectedItem?: (value: Room | null) => void;
	onSubmit?: (value?: string) => void;
	placeholder?: string;
	isInput?: boolean;
	isPicker?: boolean;
	isDateTime?: boolean;
	isSearchable?: boolean;
	isDisabled?: boolean;
	isLoading?: boolean;
	isPickerName?: boolean;
	isMultiline?: boolean;
  errorDescription?: string;
  errorDescriptionType?: string;
};
