import { memo, useCallback, useEffect, useState } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { GetReservations } from '../../api/api';
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks';
import { selectReservationsLists, selectUser, setReservationsLists } from '../../redux/slices/homeSlice';
import { useNavigate } from 'react-router-dom';
import { ResponseReservations } from '../../data/responses/responses';
import ListItemAnimation from '../../components/lists/listItemAnimation';
import ListItem from '../../components/lists/listItem';
import { useTranslation } from 'react-i18next';
import EmptyList from '../../components/reusable/emptyList/emptyList';

export default memo(function BookingCurrent() {
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const user = useAppSelector(selectUser);
	const currentReservations = useAppSelector(selectReservationsLists)?.current.list;
	const dispatch = useAppDispatch();
	const {t} = useTranslation();

	const fetchData = useCallback(async () => {
		setIsLoading(true);
		if (!user) {
			setIsLoading(false);
			return;
		};
		const response = await GetReservations({ authToken: user.token });
		if (!response) {
			setIsLoading(false);
			alert(t('api_error'));
			return;
		}
		else if (!response.ok) {
			if (response.status === 401) {
				navigate('/login',
				{
					replace: true,
					state: {
						isError: true
					}
				});
			}
			else {
				alert(t('api_error_status') + response.status);
			}
			setIsLoading(false);
			return;
		}

		const lists: ResponseReservations = await response.json();
		dispatch(setReservationsLists(lists));
		setIsLoading(false);

	}, [dispatch, navigate, t, user]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<div className='dashboard_page'>

			<label className='title'>
				{t('current_title')}
			</label>

			{
				!currentReservations || currentReservations.length === 0 ?
				<EmptyList/>
				:
				<PullToRefresh
					onRefresh={fetchData}
					pullingContent={(
						<div className='content'>
						{t('pull_to_refresh')}
						</div>
					)}>
				{
					isLoading ?
					<div className='content'>
						<ListItemAnimation/>
						<ListItemAnimation/>
						<ListItemAnimation/>
						<ListItemAnimation/>
						<ListItemAnimation/>
						<ListItemAnimation/>
						<ListItemAnimation/>
						<ListItemAnimation/>
					</div>
					:
					<div className='content'>
						{
							currentReservations?.map((item, index) => {
								return (
									<ListItem
										key={index}
										item={item}/>
								);
							})
						}
					</div>
				}
				</PullToRefresh>
			}
		</div>
	);
});
